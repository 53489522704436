.card.info-card {
  height: 100%;
  background-color: $color-gray-06;
  border: 1px solid $color-black;
  
  > .card-body {
    padding: $spacer/2 0;
    border-radius: 7px 7px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .info {
      text-align: center;
      .number {
        font-size: 2rem;
        font-weight: bold;
        vertical-align: middle;
        margin-right: $spacer/4;
      }
    }
    .warning-info, .error-info {
      text-align: center;
      font-size: $font-size-discreet;
    }
  }
  > .card-footer {
    background-color: $color-gray-04;
    font-size: $font-size-base;
    color: $color-gray-01;
    padding: 0;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: initial;
    .aw-icon {
      font-size: 1.25rem;
      margin-right: .5rem;
    }

  }
  .table {
    background-color: inherit;
    margin: 0.5rem;
    width: inherit;
    td, th {
      padding: 0.1rem;
      text-align: inherit;
    }
    td:first-child {
      padding-right: 1rem;
    }
  }

  &.warning > .card-body {
    background-color: $color-warning;
    color: $color-gray-05;
  }
  &.error > .card-body {
    background-color: $color-error;
    > .label {
      color: white;
    }
  }
}