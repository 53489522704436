#auth-page {
  min-height: 100vh;
  background-image: url(~/public/landing.webp);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  #auth-body {
    font-family: 'Gotham Medium', 'Gotham';
    width: 360px;
    box-sizing: border-box;
    padding: 64.5px 60px 37px;
    background-color: #25262c;
    border: 2px solid #5a9bff;
    border-radius: 0.75rem;
    align-items: center;
    justify-content: center;
    color: #fff;

    h1 {
      margin-top: 40.5px;
      margin-bottom: 40.5px;
      font-size: 1rem;
      line-height: 1.24;
      color: #b9bacb;
      text-align: center;
      letter-spacing: -0.4px;
    }

    button {
      text-transform: uppercase;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      background-color: #1e6ff1;
      border: none;
      height: 40px;
      width: 100%;
      color: #fff;
    }

    button:focus {
      outline: 0;
    }
    button:hover {
      background-color: #1e83ff;
      box-shadow: 0 1rem 1rem 0 rgba(0, 0, 0, 0.25);
    }
  }
}
