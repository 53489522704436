#products {
    .leased {
        margin-left: calc($spacer / 2);
        @include aw-theme(color, $color-dark-text-secondary, $color-light-text-secondary);
    }

}
.header {
    font-size: 1.2rem;
    font-weight: 400;
    color: #888;
}
.header-filter-warning {
    font-size: 1.2rem;
    font-weight: 400;
    color: #ff2211;
}
.columns-popup .header {
    font-size: 1.2rem;
    font-weight: 700;
    color: #888;
    margin-bottom: 10px;
}

.columns-popup .header > div {
    font-size: 2.5rem;
}

.columns-popup div input[type="checkbox"] {
    margin-right: 10px;
}

.not-visible {
    display: none;
}

.inline-group {
    display: inline-block;
}



///////////
.pop-container {
    width: 200px;
}

.display-column {
    padding-left: 1.5rem;
}

.date-input {
    overflow: visible;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
}

.text-center {
    text-align: center;
}
// .hide-overflow {
//     min-width: 12%;
//     padding: 0 5px;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-align: center;
//     align-items: center;
//     -ms-flex-pack: justify;
//     justify-content: space-between;
//     padding-bottom: 0.5rem;
//     padding-top: 0.5rem;
//     min-height: 50px;
// }
.hide-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search-wrapper {
    margin-left: calc($spacer / 2);
    display: inline-block;
}

.header-actions {
    display: flex;
    justify-content: flex-end;

    > .btn.basic .aw-icon {
        font-size: 20px;
    }
}
