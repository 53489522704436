$aviwest-white: #fff;
$aviwest-dark: #242424;

$gray-100: #d3d3d3;
$gray-200: #bababa;
$gray-300: #a1a1a1;
$gray-400: #888888;
$gray-500: #6f6f6f;
$gray-600: #565656;
$gray-700: #3d3d3d;
$gray-800: #242424;
$gray-900: #121212;

//$secondary: $gray-800;
$light: $aviwest-white;

$body-bg: $gray-800;
$body-color: $aviwest-white;

$breadcrumb-bg: $gray-800;
$breadcrumb-active-color: $color-primary;

$navbar-light-color: $body-bg;
$navbar-light-hover-color: darken($color-primary, 15%);
$navbar-light-active-color: $color-primary;

//$list-group-color: $aviwest-white;
//$list-group-action-color: $list-group-color;
//$list-group-bg: $gray-700;
//$list-group-border-color: $list-group-bg;
//$list-group-hover-bg: lighten($gray-700, 15%);

$pagination-bg: transparent;
$pagination-border-color: $pagination-bg;
$pagination-color: $aviwest-white;
$pagination-active-color: $color-primary;
$pagination-active-bg: $pagination-bg;
$pagination-hover-bg: $pagination-bg;
$pagination-hover-border-color: $pagination-bg;
$pagination-disabled-color: $pagination-color;
$pagination-disabled-bg: $pagination-bg;
$pagination-disabled-border-color: $pagination-bg;

$table-head-bg: transparent;
//$table-bg: $gray-700;
//$table-color: $aviwest-white;
$table-head-color: $gray-400;
//$table-border-color: $table-bg;
//$table-hover-bg: lighten($gray-700, 15%);
//$table-hover-color: $aviwest-white;

$hr-border-color: $gray-400;

$modal-content-bg: $body-color;
$modal-content-color: $body-bg;
$modal-content-border-color: $aviwest-dark;
