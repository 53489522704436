#all-consumption {
  label[for="download-csv-title"] {
    margin: 0 $spacer 0 0;
  }

  .slider {
    padding-top: $spacer * 0.5;
  }
}

.modal-zoho-accounts {
  min-width: 50%;
}
.marginTop {
  @include media-breakpoint-up(sm) {
    margin-top: -70px;
  }
}
.modal-footer-consumption {
  border: none;
}

@media (min-width: 1200px) {
  .d-xl-block-inline {
    display: inline !important;
  }
}
