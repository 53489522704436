.activity {
  display: flex;

  &:not(.last) {
    .vertical-line {
      border-left: 1px solid $hr-border-color;
      flex: 1;
      margin-left: $spacer;
    }

    .content {
      padding-bottom: $spacer;
    }
  }

  .illu {
    display: flex;
    flex-direction: column;
    width: $spacer * 2;

    .avatar {
      border: 1px solid $primary;
      border-radius: 25px;
      color: $primary;
      display: block;
      height: $spacer * 2;
      line-height: $spacer * 2;
      text-align: center;
      width: $spacer * 2;
    }
  }

  h3 {
    font-family: $font-family-base;
    line-height: $spacer * 1.5;
    font-weight: 400;
    margin: 0;
  }

  .content {
    padding-left: $spacer;
    width: 100%;

    .error {
      color: $color-red;
    }

    .metadata {
      color: $gray-300;
      font-size: $font-size-sm;

      > * {
        padding-right: $spacer * 0.25;
      }
    }
  }
}
