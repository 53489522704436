.react-flow__edge {
  path {
    stroke: $primary;
    stroke-width: 3px;
    z-index: 1;
  }
  &.disabled path {
    stroke: $color-gray-02;
  }
}

.custom-connection-line {
  stroke: $color-gray-02;
  stroke-width: 3px;
  z-index: 1;
}

.react-flow__node {
  background-color: transparent;
  
  .route-graph-input-node, .route-graph-encoder-node, .route-graph-output-node {
    .aw-control-grid-item {
      margin: 0;
      z-index: 1;
      .thumbnail {
        pointer-events: none;
      }
    }
    
    .custom-handle {
      background-color: $color-gray-06;
      border: none;
      height: 20px;
      width: 20px;
      text-align: center;
      &::after {
        content: ">";
        font-size: 20px;
        line-height: 1;
        font-weight: bold;
      }
      
      &.react-flow__handle-right {
        border-radius: 0 9999px 9999px 0;
        right: -20px;
      }
      &.react-flow__handle-left {
        border-radius: 9999px 0 0 9999px;
        left: -20px;
      }
      &.connected {
        background-color: $primary;
      }
      &.disabled,
      &.react-flow__handle-valid {
        background-color: $color-gray-02;
      }

      &.delete-btn {
        pointer-events: auto;
        cursor: pointer;
        &:hover::after {
          content: "\2715";
        }
      }
    }
  }
}

.react-flow__edges {
  z-index: 1 !important;
}


.route-graph-modal {
  margin-bottom: $spacer;
  width: 100%;
  height: calc(100% - 2.5rem);
  display: flex;

  .route-graph-nav {
    width: 90px;
    background-color: $color-nav-bg;
    margin-bottom: 0;
    flex-wrap: nowrap;
    overflow-y: hidden;
    position: sticky;
    top: calc(#{$navbar-height} + #{$spacer-sm});

    .nav-item {
      .nav-link {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .route-graph-menu {
    background-color: $color-nav-bg;
    margin-left: 3px;
    padding: $spacer;
    width: 360px;
    .aw-heading {
      font-size: 1.5rem;
    }

    .route-graph-parameters {
      margin: 0;
    }

    .route-graph-node {
      margin: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      .search-box {
        margin-bottom: $spacer;
      }
      .route-graph-node-list {
        flex: 1;
        overflow-y: scroll;
        .aw-control-grid-item {
          margin: $spacer auto;
          cursor: grab;
          &.disabled {
            cursor: not-allowed;
          }
        }
        .empty-list {
          padding: 0;
        }
      }

    }
  }
  .route-graph {
    background-color: $color-gray-06;
  }
}

.route-graph {
  flex: 1;
  border: 2px solid $color-black;
  border-left: none;
}

.react-flow {
  .react-flow__controls {
    box-shadow: none;
    > .react-flow__controls-button {
      margin: .25rem 0;
      box-sizing: inherit;
      svg {
        fill: currentColor;
      }
    }
  }
}

.react-flow .controls {    
  position: absolute;
  top: 10px;
  z-index: 10;
}