.dnd-list-element {
  border-radius: calc($border-radius / 2);
  background-color: $color-gray-04;
  margin: calc($spacer / 4) 0;

  &.is-dragging {
    opacity: .25;
  }
  
  .aw-icon {
    font-size: 1.25rem;
    vertical-align: middle;
    margin: 0 calc($spacer / 2);
  }
}
