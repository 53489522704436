.notification:not(.toast) {
  align-items: flex-start;
  background-color: lighten($green, 50%);
  border-radius: 4px;
  color: darken($green, 15%);
  display: flex;
  margin: $spacer * 0.25;
  padding: ($spacer * 0.5) $spacer;

  &.danger {
    background-color: lighten($red, 50%);
    color: darken($red, 15%);

    .btn-link {
      color: darken($red, 15%);
    }

    .btn:hover {
      color: darken($red, 30%);
    }
  }

  &.success {
    background-color: lighten($green, 50%);
    color: darken($green, 15%);

    .btn-link {
      color: darken($green, 15%);
    }

    .btn:hover {
      color: darken($green, 30%);
    }
  }

  &.warning {
    background-color: lighten($orange, 50%);
    color: darken($orange, 15%);

    .btn-link {
      color: darken($orange, 15%);
    }

    .btn:hover {
      color: darken($orange, 30%);
    }
  }

  .notif-content {
    margin: 0;
    white-space: pre-wrap;
    padding: ($spacer * 0.25) 0;
  }

  .actions {
    align-items: center;
    margin-left: auto;
    margin-right: -$spacer * 0.25;
    padding-left: $spacer;

    .btn:hover {
      text-decoration: none;
    }
  }
}

.toast.notification {

  .toast-header {
    position: relative;
    .aw-circular-badge {
      vertical-align: middle;
      margin-right: calc($spacer / 4);
      .text-container {
        font-size: .6rem;
      }
    }
    .btn.basic {
      position: absolute;
      top: 0;
      right: 2.5 * $spacer;
      font-size: $font-size-discreet;
    }
  }

}
