.register-product {

  $label-min-width: 40%;

  label {
    min-width: $label-min-width;
  }
  .invalid-feedback {
    margin-left: $label-min-width;
  }

  .form-control.is-valid,
  .form-control.is-invalid,
  .form-control {
    width: calc(100% - #{$label-min-width} - 2 * #{$spacer}) !important;
  }
}
