.notifications {
  bottom: $spacer;
  margin: 0;
  max-width: calc(100vw - #{$spacer} * 2);
  padding: 0;
  position: fixed;
  right: $spacer;
  z-index: $zindex-modal + 1;

  // MD
  @include media-breakpoint-up(md) {
    left: 50%;
    right: 0;
    transform: translate(-50%, 0);
    width: 80vw;
  }

  // LG
  @include media-breakpoint-up(lg) {
    max-width: 60vw;
  }

  // XL
  @include media-breakpoint-up(xl) {
    max-width: 700px;
  }
}

.toast-notifications {
  bottom: $spacer;
  left: $spacer;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: $zindex-modal + 1;

  .toast{
    width: 350px;
  }
}
