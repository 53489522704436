.tooltip-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  cursor: default;

  button:disabled {
    pointer-events: none;
  }
}

.input-selection {
  display: flex;
  align-items: center;
  padding: 0 .25rem !important;
  border: transparent 2px solid;

  >.id {
    display: flex;
    flex: 1;
  }

  >.status {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .badge-label {
      flex-grow: 0 !important;
    }
  }

  .aw-icon {
    vertical-align: middle;
    margin-right: calc($spacer / 4);
  }
}

.source-selector-tooltip {
  z-index: 1200;
}