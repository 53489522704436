$audioscale-legend-font-size: .7rem;

.sources {
  .header .metadata.left {
    span + span {
      margin-left: calc($spacer / 4);
    }

    .bitrate-indicator {
      font-size: 1.5rem;
    }

    .live-timer {
      font-size: 0.875rem;
      line-height: 1.5rem;
      align-self: baseline;
    }
  }
  .header .metadata.right {
    .fu-icon {
      height: 1.5em;
      margin-right: .5rem;
    }
    .battery-level {
      margin-right: .25rem;
    }
    .fu-version {
      margin-left: .25rem;
    }
  }

  .main-content .compact .content-title .aw-loader {
    z-index: 0;
  }

  .details {
    .audio-levels {
      flex: 0 0 auto;
      .audio-scale {
        width: 40px;
        display: inline-block;
        height: 100%;
        margin: 0 2px;
        color: $color-gray-02;

        > .scale {
          height: 100%;
          width: $vu-meter-width;
          position: relative;
          border: 1px solid $color-gray-02;
          border-right: none;

          .scale-danger {
            height: 12%;
            border-bottom: 1px solid $color-gray-02;
            position: relative;
          }

          .scale-warning {
            height: 24%;
            border-bottom: 1px solid $color-gray-02;
            position: relative;
          }

          span {
            font-size: $audioscale-legend-font-size;
            line-height: $audioscale-legend-font-size;
            position: absolute;
            left: 8px;
            white-space: nowrap;
            text-align: left;
          }

          .scale-min span {
            top: calc(-#{$audioscale-legend-font-size} / 2);
          }

          .scale-max,
          .scale-danger,
          .scale-warning {
            span {
              bottom: calc(-#{$audioscale-legend-font-size} / 2);
            }
          }
        }
      }
    }
    .bitrate-graph {
      height: 100%;
      width: calc(100% - 124px);
    }
  }

  .additional-content {
    .rounded {
      border-radius: 8px !important;
    }

    .network-info {
      padding: calc(#{$spacer} / 2);
    }

    .interface-toggle {
      margin: 0.75rem;
      margin-left: 2rem;

      .btn-sm .aw-icon {
        font-size: 0.7rem;
      }
    }

    .interfaces-graph {
      label {
        margin-left: 2rem;
      }
    }

    table.source-info-array,
    table.source-network-info-array {
      th.crop {
        max-width: 1px;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    table.source-info-array th {
      text-align: end;
    }
  }
}
