.aw-theme-light form:not(.basic-form).search-box,
.aw-theme-dark form:not(.basic-form).search-box {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  > .form-control {
    width: 100% !important;
    border-radius: 9999px;
    flex: 1 1 auto;
  }

  > .btn.advanced-search-trigger {
    position: absolute;
    right: calc(#{$form-control-height} + #{$spacer} / 4 + #{$form-border-width} );
    top: 2px;
  }

  > button {
    margin-left: calc($spacer / 4);
    height: $form-control-height;
    width: $form-control-height;
  }

  @media (max-width: 767px) {
    margin-bottom: $spacer;
  }

  &.with-advanced-search > .form-control {
    padding-right: calc(10px + 2em) !important;
    // &::-webkit-search-cancel-button {
    //   position:relative;
    //   right: calc(#{$form-control-height} / 2 + #{$spacer} / 4);
    // }
    &.is-invalid {
      padding-right: calc(10px + 2em + 20px) !important;
      background-position: right calc(0.375em + 0.21875rem + 2em) center;
    }

  }
}

.advanced-search {
  min-height: 150px;
  max-width: 500px;

  .form-group {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: calc($spacer / 2);

    label {
      display: inline;
      margin-right: calc($spacer / 2);
      min-width: 25%;
    }
    
    .operator {
      min-width: 30%;
      margin-right: calc($spacer / 4);
    }

    .form-control:not(.operator),
    .DayPickerInput {
      flex: 1 1 auto;
      width: 100%;
    }

    &[data-criterion-type="BETWEEN"] > :not(select).form-control ~ .form-control {
      margin-left: calc($spacer / 4);
    }
    
  }
}

.advanced-sort {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .aw-dropdown .sort-selector {
    height: 32px;
    display: inline-block;
    padding-right: calc(32px + #{$form-border-width} * 2)
  }
  .sort-operator {
    position: absolute;
    right: $form-border-width;
  }
}
