.modal-content-min-height{
    min-height: 360px;

    .no-padding {
        padding: 0 !important;
    }

    .search-box {
        margin-bottom: 1rem;
    }
    
    .list-group{
        cursor: pointer;
    }
}

.list-item-active{
    color: $color-primary !important;
}

.modal-confirm{
    min-height: 200px !important;
}
