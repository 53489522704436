.action-bar {
  .btn {
    margin-bottom: calc($spacer / 2);
    margin-left: 0 !important;
  }
}

#btn-rent, #btn-sell {
  margin-left: 0;
}

.warning-pending {
  margin-left: 10px;
  color:$color-primary;
  cursor: help;
}

.show-product-warning{	
  display:inline;
  margin-left: 10px;
	color:$warning;
	border-radius:7px;
	-moz-border-radius:4px;
	-webkit-border-radius:4px;
	text-align:center;
  position: absolute;
  z-index:10;
}

#options .btn-sticky{
  position: sticky;
} 
.btn-sticky:nth-child(1) {
  top: 100px;
}
.btn-sticky:nth-child(2) {
  top: 150px;
  @include media-breakpoint-only(md) {
    top: 170px;
  }
}
.btn-sticky:nth-child(3) {
  top: 200px;
  @include media-breakpoint-only(md) {
    top: 240px;
  }
}
.btn-sticky:nth-child(4) {
  top: 250px;
  @include media-breakpoint-only(md) {
    top: 285px;
  }
}

label[for="licenseType"] + span,
label[for="expirationDate"]  + span
{
  margin-left: $spacer;
}
