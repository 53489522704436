.aw-control-grid-item .content-title {
  .route-placeholder {
    display: flex;
    flex-direction: row;
    color: $color-gray-03;
    .badge {
      font-size: 1rem;
      font-weight: bold;
      color: $color-black !important;
      background-color: $color-gray-03;
    }
    .chevron {
      font-size: 0.5em;
      margin: 0 2px;
      font-weight: bold;
    }
    .aw-icon {
      margin: 0;
      font-size: 3rem;
    }
  }

  .forward-info {
    padding: 0.5rem 0;
    font-size: 1rem;
  }
}

.routes {
  &.layout-section .aw-heading .actions {
    justify-content: flex-start;
    padding-left: 0.5rem;
    text-transform: none;
    .streamhub-filters {
      z-index: 1100;
    }
  }
  .route-details {
    width: 100%;
    height: calc(100% - 0.625rem * 2);
    margin: 0.625rem;
    background-color: $color-gray-05;
    border-radius: $border-radius;
    > .react-flow {
      border-radius: $border-radius;
    }
    > .route-details-options.dropdown {
      position: absolute;
      right: calc(#{$spacer} / 2 + 0.625rem);
      top: calc(#{$spacer} / 2 + 0.625rem);
      z-index: 10;
    }
  }
}
