table {
  .temp {
    color: $yellow;
    display: none;
    font-size: small;
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      padding-left: $spacer;
    }
  }

  .temporary {
    td {
      background-color: $primary; /* The fallback */
      background-color: rgba($primary, 0.2);
    }

    .temp {
      display: block;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }
    }
  }

  .version-warning {
    padding-right: $spacer * 0.5;
  }

  td:not(:nth-child(1)),
  th:not(:nth-child(1)) {
    text-align: center;

    input,
    .switch {
      text-align: center;
    }
  }

  label {
    margin: 0;
  }
}

.form-group {

  &.hidden {
    display: none;
  }

}

form .global-error {
  display: block;
}