.link-item {
  align-items: center;
  background-color: $gray-700;
  border-radius: $border-radius;
  color: $aviwest-white;
  display: flex;
  justify-content: space-between;
  padding: $spacer;

  h2 {
    margin: 0;
  }

  &:hover,
  &:focus {
    background-color: lighten($gray-700, 15%);
    color: inherit;
    text-decoration: none;
  }
}
