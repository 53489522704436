.port-rules-form {
  .btn.form-collapsable-section-trigger {
    @include aw-theme(background-color, darken($color-dark-bg-box, 2), darken($color-light-bg-box, 2));

  }
  .form-collapsable-section {
    @include aw-theme(background-color, darken($color-dark-bg-box, 2), darken($color-light-bg-box, 2));
    padding: 0;
  }
  .form-group {
    margin-bottom: 0.25rem;
  }
  .no-rules {
    .aw-icon {
      font-size: 2rem;
      vertical-align: middle;
    }
  }
  .delete-rule {
    padding: 0;
  }
  .add-rule {
    margin: 0 auto;

  }
}
