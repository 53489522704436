.row-item {
  margin: 3px 0;

  .btn {
    width: 100%;
  }

  .col,
  [class*="col-"] {
    padding: 0 5px;
  }
}
