.error-alert {
  &.alert {
    margin-bottom: $spacer;
  }

  .modal-content {
    border: 5px solid $color-primary;
  }

  .modal-body {
    min-height: inherit;
  }
}
