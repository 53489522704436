.updatedOptionContent {
  text-align: left;

  &[disabled],
  &.disabled {
    cursor: unset;
    opacity: inherit;

    [class*="fa-chevron"] {
      display: none;
    }
  }

  &.btn {
    border-radius: $grid-cell-radius;
    padding: 0;

    &,
    :hover {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }

  h3 {
    text-align: left;
    padding-top: .5rem;
    padding-left: .5rem;

    .mo {
      @include media-breakpoint-up(md) {
        padding-left: calc($spacer * 0.5);
      }
    }
  }

  .link-item {
    background: transparent;
    border-radius: 0;
    padding: calc($spacer / 2);
    text-align: left;
    @include aw-theme(color, $color-dark-text-secondary, $color-light-text-secondary)
  }

  li {
    list-style: none;
    margin: 0;
    width: 100%;
  }

  .aw-icon {
    line-height: 1.5;
    padding-right: calc($spacer * 0.5);
    vertical-align: top;
  }

  .collapse,
  .collapsing {
    padding-left: calc($spacer * 4);

    .details-list {
      padding-top: calc(0.5 * $spacer);
    }

    .modification-details {
      display: inline-block;
      padding-inline-start: 0;
    }
  }
}
