@import './source-selector/source-selector';
@import './sources/source/source';
@import './routes/routes';

$header-height: 48px;
$limits-width: 2px;
$header-margin: $grid-cell-margin;
$layout-mobile-min-height: calc(#{$grid-cell-height} + (2 * #{$grid-cell-padding}));
$sh-header-badge-widht-height: 45px;

.navbar.aw-navbar + .dashboard {
  height: 100vh;
  padding: $spacer;
  padding-top: $navbar-height;
}

.flex-horizontal {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.layout-section {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  display: block;

  > .content {
    height: calc(100% - #{$header-height} - #{$header-margin});
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: $header-margin;

    .aw-control-grid-item {
      .header {
        .title {
          font-size: 1rem !important;

          .btn.basic {
            vertical-align: baseline;
          }
        }
      }
    }
  }
}

.gutter {
  display: flex;
  overflow: hidden;

  .separator {
    flex: 0 0 auto;

    @include aw-theme(background-color, $color-dark-delimiter, $color-light-delimiter);
  }

  &.gutter-horizontal {
    flex-direction: row;
    justify-content: center;
    margin: 0 10px;
    cursor: col-resize;

    .separator {
      width: $limits-width;
      margin-top: 0;
      height: 100%;
    }
  }

  &.gutter-vertical {
    flex-direction: column;
    justify-content: flex-end;
    cursor: row-resize;
    margin: 10px 0;

    .separator {
      width: 100%;
      height: $limits-width;
    }
  }
}

.form-check-label {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}

.outputs-add {
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  font-size: 5em;
  cursor: pointer;
  @include aw-theme(color, $color-dark-text-secondary, $color-light-text-secondary);

  &.collapsed {
    font-size: 1.75em !important;
  }
}

#new-output-modal,
#edit-output-modal {
  .buttons-wrapper {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
  }

  .encoder-section,
  .receiver-selector {
    margin-top: $spacer;
  }
}

.aw-control-grid-item {
  &.droppable {
    box-shadow: 0 0 0 1px $color-success;
  }

  .metadata {
    span + .aw-icon {
      margin-left: calc($spacer / 4);
    }
  }
}

@media (max-width: map-get($grid-breakpoints, 'md')) {
  .layout-section {
    height: auto;

    > .content {
      overflow: auto;
      height: auto;
      min-height: $layout-mobile-min-height;
    }
  }
}

.streamhub-header-monitor-info-badge {
  height: $sh-header-badge-widht-height;
  width: $sh-header-badge-widht-height;
  @include aw-theme(background-color, $color-gray-05, $color-light-bg-box);
  border-radius: calc($border-radius / 2);
  padding: calc($spacer / 4) 0;
  margin-top: $grid-section-header-padding;
  margin-right: $grid-section-header-padding;

  .icon {
    text-align: center;
    font-size: 1.25rem;
    line-height: 1;
  }
  .info {
    text-align: center;
    font-size: 1rem;
  }
}

.aw-control-grid-item .thumbnail .thumb-error, .aw-input .thumbnail .thumb-warning {
  text-align: center;
  font-size: 14px;
}