* {
  --bs-table-bg: $color-dark-bg-box;
  --bs-table-color: $color-dark-text-secondary;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

section,
section ~ aside {
  margin-top: 1rem;
  padding: 0;
}

h2 {
  line-height: 2.25rem;
}

span,
div {
  & ~ .aw-icon {
    margin-left: $spacer * 0.5;
  }
}

ul {
  margin: 0;
}

.aw-icon {
  & ~ span,
  div {
    //padding-left: $spacer * 0.5;
  }
}

hr {
  border-top-color: $hr-border-color;
  width: 50%;
}

.container-fluid {
  .action-bar {
    margin-top: $spacer * 2;

    @include media-breakpoint-up(md) {
      margin-top: calc(40px + #{$spacer} * 0.5);
    }
  }
}

.content-part {
  @include media-breakpoint-up(md) {
    border-left: 1px solid $hr-border-color;
  }

  .more {
    display: block;
    text-align: right;
  }
}



.list {
  .lhead {
    margin-bottom: $spacer;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    h2 {
      color: $gray-400;
      font-size: 0.9rem;
      line-height: 1rem;
      margin: 0;
      padding: $spacer * 0.5;
    }
  }
}

:not(.compact) > .actions {
  display: flex;

  > .btn:last-child {
    @media (min-width: 767px){
      margin-right: calc($spacer / 2);
    }
  }
}

.table {
  @include aw-theme(background-color, $color-dark-bg-box, $color-light-bg-box);

  &.table-hover {
    cursor: pointer;
  }

  thead {
    //background-color: $table-head-bg;
    color: $table-head-color;
  }
}

#auth-layout,
#auth-background,
#auth-body {
  margin-top: 0;
}

.illustration {
  font-size: 60px;
  padding: 0 0 20px;
  text-align: center;
}

label.required::after {
  content: "*";
  color: $orange;
}

.aw-popup-subheader {
  @include aw-theme(color, $color-dark-text-secondary, $color-light-text-secondary);
  margin-bottom: calc($spacer / 2);
  margin-top: $spacer;
}

.modal-content {
  color: inherit;
}

.modal-confirm-action {
  margin-top: 2 * $spacer;
}

.btn *.sr-only + .aw-icon {
  margin-left: 0;
}

.btn .aw-icon + *.sr-only {
  margin-right: 0;
}

.buttons {
  display: flex;
  justify-content: flex-end;

  .btn {
    margin-left: calc($spacer / 2);
  }
}

.dropzone {
  padding: 2rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  border-style: dashed;
  border-width: 1px;
  text-align: center;

  @include aw-theme(color, $color-dark-text-secondary, $color-light-text-secondary);
  @include aw-theme(border-color, $color-dark-text-secondary, $color-light-text-secondary);

  &.target {
    border-color: $color-primary;
    border-style: solid;
    color: $color-primary;
  }

  &.error {
    border-color: $color-error;
    border-style: solid;
    color: $color-error;
  }

  .placeholder {
    font-size: 1.2rem;
  }
}

.empty-list {
   padding: 3rem 0;

  .aw-empty {
    max-width: 400px;
  }
}

.aw-modal-content {
  .help-layout .alert {
    font-size: inherit;
    display: inherit;
  }
  .alert {
    font-size: initial;
    display: inline-block;
  }
}

.list-group {
  .list-group-item {
    &.active {
      color: $color-primary !important;
    }

  }
  &.is-invalid {
    .list-group-item {
      border-color: $color-error;
      color: $color-error;
    }
  }
}
