.left-menu-layout {
  display: flex;
  min-height: 100vh;

  .navbar.aw-navbar + & {
    padding-top: calc(#{$navbar-height} + #{$spacer-sm});
  }

  .menu {
    width: 90px;
    background-color: $color-nav-bg;
    margin-bottom: 0;
    flex-wrap: nowrap;
    overflow-y: hidden;
    position: sticky;
    top: calc(#{$navbar-height} + #{$spacer-sm});
    height: calc(100vh - #{$navbar-height} - #{$spacer-sm});

    .nav-item {
      .nav-link {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .list-group > .list-group-item .buttons.row {
      width: 100px;
    }
  }

  :not(.aw-list) > .list-group > .list-group-item:not(.link-item) {
    padding-left: $spacer;
    padding-right: $spacer;
    &.cloud-products-header {
      font-style: italic;
      padding-top: calc($spacer / 2);
      padding-bottom: calc($spacer / 2);
    }
  }
}

.side-panel-layout {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-x: clip;

  .gutter {
    margin: 0px;
    width: 10px;
    z-index: 3;
    top: calc(#{$navbar-height} + #{$spacer-sm});
    height: calc(100vh - #{$navbar-height} - #{$spacer-sm});
    overflow-y: auto;
    overflow-x: clip;
    position: sticky;
    .separator {
      width: 5px;
      transform: translate(3px);
      background-color: $color-nav-bg;
    }
  }

  &.disabled > .gutter {
    pointer-events: none;
  }

  .width-animation {
    transition: width 0.2s ease-in-out;
  }

  .side-panel-main > * {
    min-width: 600px;
  }

  .side-panel {
    background-color: $color-nav-bg;
    z-index: 3;
    top: calc(#{$navbar-height} + #{$spacer-sm});
    height: calc(100vh - #{$navbar-height} - #{$spacer-sm});
    overflow-y: auto;
    overflow-x: clip;
    position: sticky;
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
    }

    .aw-heading {
      justify-content: space-between;
    }
  }
}

.version {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.state-info {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1;
}

.state-badge {
  margin: 2px;
  display: inline-block;
  border-radius: $border-radius / 2;
  background-color: $color-black;
  border: 1px solid $color-gray-04;
  color: $color-gray-04;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2rem;
  height: 1.25rem;
  width: 1.25rem;

  &.state-badge-disabled {}
  &.state-badge-on {
    background-color: $color-gray-06;
    border-color: $color-gray-02;
    color: $color-gray-02;
  }
  &.state-badge-connecting {
    background-color: $color-gray-06;
    border-color: $white;
    color: $white;
  }
  &.state-badge-live {
    background-color: $white;
    border-color: $white;
    color: $color-gray-05;
  }
  &.state-badge-warning {
    background-color: $color-warning;
    border-color: $color-warning;
    color: $color-gray-05;
  }
  &.state-badge-error {
    background-color: $color-error;
    border-color: $color-error;
    color: $white;
  }
}

textarea {
  min-height: 100px;
}

.row.reduced-margin {
  margin-left: -8px;
  margin-right: -8px;
  > .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-left: 8px;
    padding-right: 8px;
  }
}
