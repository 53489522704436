#account {
  .heading {
    :nth-child(n + 3) {
      margin-left: $spacer * 0.5;
    }
  }
}

.no-margin{
  margin-left: 0.0rem !important;
}

.margin-1lr{
  margin-left: 1.0rem !important;
  margin-right: 1.0rem !important;
}

.subtr{
  padding-left: 2rem !important;
}

.legend{
    display: flex;
    flex-direction: row;
    list-style: none;
    text-align: center;
}

.legend-item{
  padding-left: 10px;
  padding-right: 10px;
}

.view-link{
  color: $primary !important;
}
.active-consumption{
  color: #2da432 !important;
}
@media screen and (max-width: 640px) {
  #tb-consumption> thead > tr > th {
    max-width: 0px;
  }
}

.compute-unlimited-switch {
  display: inline-block;
  >.switch{
    vertical-align: middle;
  }
  >#label-Compute-unlimited {
    vertical-align: middle;
  }
}