.heading {
  align-items: center;
  display: flex;

  > :nth-child(2) {
    margin-left: $spacer;
  }

  > :nth-child(n + 2) {
    margin-bottom: $spacer * 0.5;
  }
}
