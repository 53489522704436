@import "./assets/styles/variables";
@import "node_modules/@hai/aviwest-ui-kit/dist/styles/index.scss";
@import "./assets/styles/colors";

@import "./assets/styles/general";
@import "./assets/styles/alert";
@import "./assets/styles/form";
@import "./assets/styles/slide";

@import "components/common/dnd-list/dnd-list";
@import "components/common/navbar/navbar";
@import "components/common/heading/heading";
@import "components/common/link-item/link-item";
@import "components/common/month-slider/month-slider";
@import "components/common/notification/notification";
@import "components/common/register-product/register-product";
@import "components/common/row-item/row-item";
@import "components/common/search-box/search-box";
@import "components/common/pie-chart/pie-chart";
@import "components/common/language-selector/language-selector";
@import "components/common/port-rules-form/port-rules-form";
@import "components/common/info-card/info-card";
@import "components/common/route-graph/route-graph";

@import "components/layouts/error-alert/error-alert";
@import "components/layouts/notifications/notifications";
@import "components/pages/auth/auth";
@import "components/pages/admin/accounts/account/account";
@import "components/pages/admin/accounts/accounts";
@import "components/pages/admin/activities/actions";
@import "components/pages/admin/activities/activity/activity";
@import "components/pages/admin/activities/updated-option-content/updatedOptionContent";
@import "components/pages/admin/products/receivers/receiver/product";
@import "components/pages/admin/products/products";
@import "components/pages/admin/products/receivers/receiver/account-rent-or-sell-form/account-rent-or-sell-form";
@import "components/pages/console/dashboard/console.dashboard";
@import "components/pages/console/fleet/console.fleet";

/**
 * until https://github.com/facebook/create-react-app/issues/11771
 * gets fixed, let's hide the problem and not address it
 */
 body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
    display: none;
}

// https://stackoverflow.com/a/71069137
input {
    color-scheme: dark;
}

.aw-control-grid-item {
    font-family: 'Roboto Condensed';
    letter-spacing: 0;
}

.debug {
    position: absolute;
    font-family: 'monospace';
    z-index: 10;
    width: 100%;
    font-size: 10px;
    word-break: break-all;
    background-color: #000000bb;
    &:hover {
        opacity: 30%;
    }
}